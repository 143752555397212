import {components} from '../../utils/appRessource';
import './header.scss';

function Header() {
  return (
    <div className="header pt-4 pl-4 flex items-center">
      <components.filterDate />
    </div>
  );
}

export default Header;
