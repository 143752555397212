// Import Global Function
import { useTranslation } from 'react-i18next';

//Import Pages
import Login from '../pages/Login/Login';
import Home from '../pages/Home/Home';
import Dashboard from '../pages/Dashboard/Dashboard';
import NotFound from '../pages/NotFound/NotFound';

// Import Components
import Header from '../components/Header/Header';
import SideBar from '../components/SideBar/SideBar';
import Loader from '../components/Loader/Loader';
import List from '../components/List/List';
import DialogPop from '../components/DialogLogout/DialogLogout';
import DialogDetailsOrder from '../components/DialogDetailsOrder/DialogDetailsOrder';
import FilterDate from '../components/FilterBar/FilterDate';
import Charts from '../components/PieChart/PieChart';

//Import Icons
import { IoBuildOutline, IoChevronDownOutline, IoCheckmark, IoClose, IoCalendar} from "react-icons/io5";
import { RiHome3Fill } from "react-icons/ri";
import { HiLogout, HiEye } from "react-icons/hi";

// Import Logos
import { ReactComponent as EtamLogo} from "../assets/logos/etam.svg";
import { ReactComponent as UndizLogo} from "../assets/logos/undiz.svg";
import { ReactComponent as Maison123Logo} from "../assets/logos/maison123.svg";
import { ReactComponent as LivyLogo} from "../assets/logos/livy.svg";


const functions = {
  translate: useTranslation,
}

const pages = {
  login: Login,
  home: Home,
  dashboard: Dashboard,
  notfound: NotFound
}

const components = {
  header: Header,
  sidebar: SideBar,
  loader: Loader,
  list: List,
  dialogLogout: DialogPop,
  dialogDetailOrder: DialogDetailsOrder,
  filterDate: FilterDate,
  pieChart: Charts
}

const icons = {
 home: RiHome3Fill,
 settings: IoBuildOutline,
 logout: HiLogout,
 arrowDown: IoChevronDownOutline,
 selected: IoCheckmark,
 eyeOpen: HiEye,
 close: IoClose,
 calendar: IoCalendar
}

const logos = {
  etam: EtamLogo,
  maison123: Maison123Logo,
  livy: LivyLogo,
  undiz: UndizLogo,
}

export { functions, pages, components, icons, logos };

