export const GET_USERS = 'GET_USERS';
export const USERS_ERROR = 'USERS_ERROR';
export const IS_LOGGED = 'IS_LOGGED';

export const GET_SEARCH = 'GET_SEARCH';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const FITLER_LOADING = 'FITLER_LOADING';
export const FILTER_DATETO = 'FILTER_DATETO';
export const FILTER_DATEFROM = 'FILTER_DATEFROM';
export const FILTER_SITE = 'FILTER_SITE';
export const FILTER_ORDERTYPE = 'FILTER_ORDERTYPE';

export const GET_DATABUSINESS = 'GET_DATABUSINESS';
export const DATABUSINESS_ERROR = 'DATABUSINESS_ERROR';
export const IS_KIOSKING = 'IS_KIOSKING';



