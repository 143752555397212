export class Websocket {

    messageMap = new Map();

    constructor() {
        this.connect();
    }

    connect() {
        this.websocket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
        this.websocket.addEventListener("open", this.onConnect);
        this.websocket.addEventListener("error", this.onError);
        this.websocket.addEventListener("close", this.onClose);
        this.websocket.addEventListener("message", this.onMessage);
    }

    onConnect = () => {
        if (this.websocket.readyState !== 1) {
            return;
        }
        this.connected = true;
        console.log("connected");
        this.setupPing();
        if (this.waitMessages) {
            for (const waitMessage of this.waitMessages) {
                this._sendMessage(waitMessage.action, waitMessage.data, waitMessage.messageId);
            }
        }
    }

    onClose = () => {
        setTimeout(() => this.connect, 2000);
    }

    onError = () => {
        setTimeout(() => this.connect, 2000);
    }

    setupPing() {
        this.timeout = setTimeout(() => {
            this.websocket.send(JSON.stringify({action: "ping"}));
            this.setupPing();
        }, 20000)
    }

    onMessage = (event) => {
        const msg = JSON.parse(event.data);
        if (this.messageMap.has(msg.messageId)) {
            const messager = this.messageMap.get(msg.messageId);
            messager.processMessage(msg);
            this.messageMap.delete(messager.messageId);
        }
    }

    sendMessage(action, data) {
        const message = new SendMessage(this, action, data);
        this.messageMap.set(message.messageId, message);
        return message.sendMessage();
    }

    _sendMessage(action, data, messageId) {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        const msg = {action: action, ...data, messageId}
        console.log(this.websocket.readyState);
        if (this.connected) {
            this.websocket.send(JSON.stringify(msg));
            this.setupPing();
        } else {
            if (!this.waitMessages) {
                this.waitMessages = [{action: action, data, messageId}];
            } else {
                this.waitMessages.push({action: action, data, messageId});
            }
        }
    }
}

export class SendMessage {

    constructor(websocket, action, data) {
        this.websocket = websocket;
        this.action = action;
        this.data = data;
        this.messageId = `${action}-${new Date().getTime()}`;
    }

    processMessage(msg) {
        if (msg.messageId === this.messageId) {
            this.resolve(msg.result);
            return true;
        }
        return false;
    }

    sendMessage() {
        return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            this.websocket._sendMessage(this.action, this.data, this.messageId);
        });
    }
}

export const websocketInstance = new Websocket();
