
function NotFound() {
  return (
    <div className="404-page">
      404 NotFound
    </div>
  );
}

export default NotFound;
