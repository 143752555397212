import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { components } from '../../utils/appRessource';
import {useDispatch, useSelector} from 'react-redux';
import { setFilterSite } from '../../redux/actions/searchAction';

function Dashboard() {
  const dispatch = useDispatch();
  const kiosking = useSelector(state => state.searchList.kiosking);

  let { id } = useParams();
  const [currentId, setCurrentId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentId !== id) {
      dispatch(setFilterSite(id.toLocaleUpperCase()+'_FR'));
      setCurrentId(id)
      setIsLoading(false);
    }
  //eslint-disable-next-line
  }, [id]);

  return (
    <div className="dashboard-page page">
        {
          !isLoading &&
          <div className='page-content'>
            <components.pieChart />
            {!kiosking && <components.header id={id} />}
            {!kiosking && <components.list />}
          </div>
        }
    </div>
  );
}

export default Dashboard;
