import * as types from '../types';

export const setSearchInfo = (data) => async dispatch => {
    if (data) {
        dispatch({
            type: types.GET_SEARCH,
            payload: data
        })
    } else {
        dispatch({
            type: types.SEARCH_ERROR,
            payload: data,
        })
    }
}

export const setFilterDateFrom = (date) => async dispatch => {
    if (date) {
        dispatch({
            type: types.FILTER_DATEFROM,
            payload: date,
        })
    }
}

export const setFilterDateTo = (date) => async dispatch => {
    if (date) {
        dispatch({
            type: types.FILTER_DATETO,
            payload: date,
        })
    }
}

export const setFilterSite = (site) => async dispatch => {
    dispatch({
        type: types.FILTER_SITE,
        payload: site,
    })
}

export const setFilterOrderType = (orderType) => async dispatch => {
    if (orderType) {
        dispatch({
            type: types.FILTER_ORDERTYPE,
            payload: orderType,
        })
    }
}

export const setBusinessData = (info) => async dispatch => {
    if (info) {
        dispatch({
            type: types.GET_DATABUSINESS,
            payload: info
        })
    } else {
        dispatch({
            type: types.DATABUSINESS_ERROR,
            payload: info,
        })
    }
}

export const isKiosking = (value) => async dispatch => {
    dispatch({
        type: types.IS_KIOSKING,
        payload: value,
    });
}
