import { websocketInstance } from "./websocket";
import { getToken } from './auth.service';

const search = async (dateFrom, dateTo, page, site) => {
	const token = await getToken();
	const sites = site ? '&site=' + site : '';

	const response = await fetch(`${process.env.REACT_APP_API_URL}/search?page=${page}${sites}`, {
		method: 'post',
		mode: 'cors',
		headers: {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			dateFrom,
			dateTo
		})
	});
	if (response.ok) {
		return response.json();
	}
	const err = await response.json();
	throw err;
};

const getDetailsOrder = async (orderId, site) => {
	const token = await getToken();
	const response = await fetch(`${process.env.REACT_APP_API_URL}/order?orderNo=${orderId}&site=${site}`, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Authorization': 'Bearer ' + token
		}
	});
	if (response.ok) {
		return response.json();
	}
	const err = await response.json();
	throw err;
};

const getDataBusiness = async (dateFrom, dateTo, site) => {

	try {
		const token = await getToken();
		const date = new Date();
		const today = date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2);
		if (!dateTo) {
			dateTo = today;
		}
		if (!dateFrom) {
			dateTo = dateFrom;
		}
		return websocketInstance.sendMessage("stats", {
			token: `Bearer ${token}`,
			site: site,
			dateFrom,
			dateTo
		});
		// return fetch(`${process.env.REACT_APP_API_URL}/stats?dateFrom=${dateFrom}&dateTo=${dateTo}${sites}`, {
		//   method: 'GET',
		//   mode: 'cors',
		//   headers: {
		//     'Authorization': 'Bearer ' + token
		//   }
		// })
		//   .then(response => response.json())
		//   .then(data => {
		//     return data;
		//   });
	} catch (err) {

	}
};

const searchService = {
	search,
	getDetailsOrder,
	getDataBusiness
}

export default searchService;
