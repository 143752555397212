import React, { useEffect, useState, Fragment}from 'react';
import { Dialog, Transition } from '@headlessui/react';
import searchService from '../../services/search.service'; 
import { useSelector } from 'react-redux';
import { icons, components } from '../../utils/appRessource';
import './dialog.scss';

export default function DialogDetailsOrder(props) {
  const open = props.open;
  const closeModal = props.onClose;
  const orderId = props.orderId;
  const orderSite = props.orderSite;
  
  const [isLoading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState();
  const [orderErrorData, setOrderErrorData] = useState();
  const [dateFormated, setDateFormatted] = useState("");

  const handleFetchList = async () => {
    setLoading(true);
    try {
      const data = await searchService.getDetailsOrder(orderId, orderSite);
      if (data) {
        if (data.error) {
          setOrderErrorData(data.errorMessage);
        } else {
          setOrderData(data);
          const date = new Date(data.orderDate);
          const formatDate = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
          setDateFormatted(formatDate);
        }
      }
    } catch (err) {
      console.log(err);
      setOrderErrorData(err.errorMessage);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open && orderId) {
      handleFetchList();
    }
  // eslint-disable-next-line
  },[open, orderId]);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto page">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-screen-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <div className="mt-4 flex gap-8 justify-center cursor-pointer">
                <icons.close onClick={closeModal} className="orderDetails__close"/>
              </div>
              {isLoading
              ? 
                <components.loader color={"black"}/>
              : 
                orderData
                ?
                  <div className="flex flex-col pt-5">
                    <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900">
                      Commandes N° <span className='underline'>{orderData.orderNo}</span>
                    </Dialog.Title>
                    <div className='flex justify-between'>
                      <div className='flex flex-col w-2/4'>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900 pt-4">
                          <span className='font-semibold underline'>Nom:</span>  <span className='capitalize'>{orderData.customerName}</span>
                        </Dialog.Title>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900">
                          <span className='font-semibold underline'>Date:</span>  <span>{dateFormated}</span>
                        </Dialog.Title>
                      </div>

                      <div className='flex flex-col w-2/4'>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900 pt-4">
                         <span className='font-semibold underline'>Email:</span>  <span className='lowercase'>{orderData.customerEmail}</span>
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className='flex justify-between'>
                      <div className='flex flex-col w-2/4'>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900 pt-4">
                          <span className='font-semibold underline'>Status commande:</span>  <span className='capitalize'>{orderData.orderStatus}</span>
                        </Dialog.Title>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900">
                          <span className='font-semibold underline'>Status paiement:</span>  <span>{orderData.paymentStatus}</span>
                        </Dialog.Title>
                      </div>

                      <div className='flex flex-col w-2/4'>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900 pt-4">
                         <span className='font-semibold underline'>Total:</span>  <span className='lowercase'>{orderData.orderTotal}</span>
                        </Dialog.Title>
                        <Dialog.Title as="h3" className="text-sm font-medium leading-6 text-gray-900">
                         <span className='font-semibold underline'>Status shipping:</span>  <span className='lowercase'>{orderData.shippingStatus}</span>
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>
                :
                  <div className='error'>{orderErrorData}</div>
              }
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}