import React from 'react';
import ReactDOM from 'react-dom/client';

import {Provider} from 'react-redux';
import store, {Persistor} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';

import './i18n';
import App from './App';
import {websocketInstance} from "./services/websocket";
import {relogin} from "./services/auth.service";
import {BrowserRouter} from "react-router-dom";

(async function () {
  websocketInstance.connect();
  await relogin();
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <PersistGate Loading={null} persistor={Persistor}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
})();
