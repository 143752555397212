import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";

import {components, functions} from '../../utils/appRessource';
import './login.scss';
import {login, dashboardLogin} from "../../services/auth.service";

function Login() {
  const {t} = functions.translate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isSubmitDisable, setSubmitDisable] = useState(true);
  const [errorLogin, setErrorLogin] = useState('');

  const ifFormNotEmpty = email.trim().length > 0 & password.trim().length > 0;

  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };

  const handleChangePassword = event => {
    setPassword(event.target.value);
  };

  const formLoginEmpty = () => {
    if (ifFormNotEmpty) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }

  const handleFetchLogin = async (e) => {
    e.preventDefault();
    if (ifFormNotEmpty) {
      setLoading(true);
      const data = await login(email, password);
      if (data) {
        handleLogin(data);
      }
    }
  }

  const handleLogin = (data) => {
    if (data.token) {
      return (<Navigate to="/" replace={true}/>)
    } else {
      setErrorLogin(data.errorMessage);
    }
    setLoading(false);
  }

  useEffect(() => {
    formLoginEmpty();
  });

  return (
    <div className="login-page page">
      <form className="login__leftPart" onSubmit={handleFetchLogin}>
        <h1 className="text-3xl font-bold">
          {t('login.title')}
        </h1>
        <div className="login__formContainer my-8">
          <div className="login__formGroup">
            <label className="login__emailLabel">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                {t('login.email.label')}
              </span>
              <input type="email" name="email" onChange={handleChangeEmail}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full sm:text-sm focus:ring-1"/>
            </label>
          </div>
          <div className="login__formGroup mt-8">
            <label className="login__passwordLabel">
              <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                {t('login.password.label')}
              </span>
              <input type="password" name="password" onChange={handleChangePassword}
                className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full sm:text-sm focus:ring-1"/>
            </label>
          </div>
        </div>
        {errorLogin && !isLoading &&
          <div className='error login__error'>{errorLogin}</div>
        }
        <button type="submit" disabled={isSubmitDisable} className={`btn btn-primary ${isSubmitDisable ? '-disabled' : ''}`}>
          {
            isLoading
              ?
              <components.loader color={"white"}/>
              :
              t('login.submit')
          }
        </button>
      </form>
      <div className="login__rightPart bg-cover bg-no-repeat bg-top"></div>
    </div>
  );
}

export default Login;
