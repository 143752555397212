import {useEffect, useState} from 'react';
import {functions } from '../../utils/appRessource';
import './filterbar.scss';

import { useDispatch } from 'react-redux';
import { setFilterDateFrom, setFilterDateTo } from '../../redux/actions/searchAction';


function FilterDate() {
  const dispatch = useDispatch();
  const { t } = functions.translate();

  const oDate = new Date();
  const today = oDate.getFullYear()+'/'+('0' + (oDate.getMonth()+1)).slice(-2)+'/'+('0' + oDate.getDate()).slice(-2);
  const filterDates = [
    {
      id: 1,
      name:  t('filter.today'),
      value: 'today',
    },
    {
      id: 2,
      name: t('filter.week'),
      value: 'week',
    },
    {
      id: 3,
      name: t('filter.month'),
      value: 'month',
    },
  ]
  const defaultFilter = filterDates[0];
  const [currentDate, setCurrentDate] = useState(defaultFilter);

  const getDate = (selected) => {
    let dateFrom = null;
    const dateCopy = new Date(oDate.getTime());
    if (selected.value === 'today') {
      dateFrom = today;
    } else if (selected.value === 'week') {
      dateCopy.setDate(dateCopy.getDate() - 7);
      dateFrom = dateCopy.getFullYear()+'/'+('0' + (dateCopy.getMonth()+1)).slice(-2)+'/'+('0' + dateCopy.getDate()).slice(-2);
    } else if (selected.value === 'month'){
      dateCopy.setDate(dateCopy.getDate() - 30);
      dateFrom = dateCopy.getFullYear()+'/'+('0' + (dateCopy.getMonth()+1)).slice(-2)+'/'+('0' + dateCopy.getDate()).slice(-2);
    }
    dispatch(setFilterDateFrom(dateFrom));
  }

  const changeDate = (dateSelected) => {
    getDate(dateSelected);
    setCurrentDate(dateSelected)
    dispatch(setFilterDateTo(today));
  };

  useEffect(() => {
    if(currentDate === defaultFilter) {
      dispatch(setFilterDateTo(today));
      getDate(defaultFilter);
    }
  // eslint-disable-next-line
  }, [currentDate]);

  return (
    <div className="filterDate__container w-fit gap-8 py-3 px-3 rounded-xl flex items-center justify-start">
      {filterDates.map((filterDate, i) => {
        return(
          <div className={`filterDate__item rounded-full font-semibold ${filterDate.value === currentDate.value ? '-active' : ''}`} onClick={() => changeDate(filterDate)} key={i}>
            {filterDate.name}
          </div>
        )
      })}
    </div>
  );
}

export default FilterDate;