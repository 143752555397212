import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import searchService from '../../services/search.service';
import {components, functions} from '../../utils/appRessource';
import './pieChart.scss';
import {Chart} from "react-google-charts";

let lastSiteId;

function Charts(props) {
    const {t} = functions.translate();

    const [chartData, setChartData] = useState(null);
    const [values, setValues] = useState(null);
    const [chartErrorData, setChartErrorData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [dataOrderStatus, setDataOrderStatus] = useState([]);
    const [dataPaymentStatus, setDataPaymentStatus] = useState([]);
    const [dataShipmentStatus, setDataShipmentStatus] = useState([]);
    const isHome = props.isHome;

    const dateTo = useSelector(state => state.searchList.dateTo);
    const site = useSelector(state => state.searchList.site);

    const styles = {
        root: {
            textAlign: "center",
            position: "relative",
            width: "100%"
        }
    };

    const handleFetchData = async () => {
        if (site === lastSiteId) {
            return;
        }
        lastSiteId = site;
        setLoading(true);
        const data = await searchService.getDataBusiness(dateTo, dateTo, site);
        if (data) {
            if (data.error) {
                setChartErrorData(data.errorMessage);
            } else {
                if (site) {
                    setChartData(data.camembers[site]);
                } else if (isHome) {
                    setChartData(data.camembers);
                }
                if (data.values) {
                    console.log(data.values);
                    setValues(data.values);
                }
            }
            setLoading(false);
        }
    }

    const getBusinessData = () => {
        if (chartData) {
            let arrayOrderStatus = Object.keys(chartData.orderStatusStats || {}).map((key) => {
                return [key, chartData.orderStatusStats[key]]
            });
            arrayOrderStatus.unshift(["Status", "OrderStatus"]);

            let arrayPaymentStatus = Object.keys(chartData.paymentStats || {}).map((key) => {
                return [key, chartData.paymentStats[key]]
            });
            arrayPaymentStatus.unshift(["Payment", "Stats"]);

            let arrayShipmentStatus = Object.keys(chartData.shipmentStats || {}).map((key) => {
                return [key, chartData.shipmentStats[key]]
            });
            arrayShipmentStatus.unshift(["Shipment", "Stats"]);

            setDataOrderStatus(arrayOrderStatus);
            setDataPaymentStatus(arrayPaymentStatus);
            setDataShipmentStatus(arrayShipmentStatus);
        }
    }

    useEffect(() => {
        handleFetchData();
        // eslint-disable-next-line
    }, [site]);

    useEffect(() => {
        if (chartData && !isLoading) {
            getBusinessData()
        }
        // eslint-disable-next-line
    }, [chartData])

    const options = {
        legend: "bottom"
    }

    return (
        <div className="charts__container">
            {isLoading
                ?
                <components.loader color={"black"}/>
                :
                chartErrorData && !chartData
                    ?
                    <div className='error'>{chartErrorData}</div>
                    :
                    <div>
                        <div className="datas mb-4">
                            <div className="bloc text-center">
                                <div
                                    className="title xl:text-2xl lg:text-base font-bold text-center">{t('chart.titleBusiness')}</div>
                                <div className='data mt-5'>
                                    <h2 className='xl:text-lg lg:text-base font-bold'>{t('chart.titleOrdersNumber')}</h2>
                                    <p className='xl:text-4xl lg:text-base'>{values && values.nbOrdersToday}</p>
                                </div>
                                <div className='data mt-5'>
                                    <h2 className='xl:text-lg lg:text-base font-bold'>{t('chart.titleOrdersShop')}</h2>
                                    <p className='xl:text-4xl lg:text-base'>{values && values.shopValue} %</p>
                                </div>
                            </div>
                            <div className="bloc text-center">
                                <div
                                    className="title xl:text-2xl lg:text-base font-bold text-center">{t('chart.titleFluxOrder')}</div>
                                <div className='data mt-5'>
                                    <h2 className='xl:text-xl lg:text-base font-bold'>{t('chart.titleOrdersFailed')}</h2>
                                    <p className='xl:text-4xl lg:text-base'>{values && values.failedValue} %</p>
                                </div>
                                <div className='data mt-5'>
                                    <h2 className='xl:text-lg lg:text-base font-bold'>{t('chart.titleOrdersNotExported')}</h2>
                                    <p className='xl:text-4xl lg:text-base'>{values && values.nonExported}</p>
                                </div>
                            </div>
                            <div className="bloc text-center">
                                <div
                                    className="title xl:text-2xl lg:text-base font-bold text-center">{t('chart.titleFluxShipping')}</div>
                                <div className='data mt-5'>
                                    <h2 className='xl:text-xl lg:text-base font-bold'>{t('chart.titleOrderShippedNotPaid')}</h2>
                                    <p className='xl:text-4xl lg:text-base'>{values && values.nbShippedNotPayed}</p>
                                </div>
                                <div className='data mt-5'>
                                    <h2 className='xl:text-lg lg:text-base font-bold'>{t('chart.titleOrdersNotComplete')}</h2>
                                    <p className='xl:text-4xl lg:text-base'>{values && values.partialValue} %</p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    );
}

export default Charts;
