import React, { Fragment, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import { components, pages } from './utils/appRessource';
import { useDispatch, useSelector } from 'react-redux';
import './style/style.scss';
import { isKiosking } from "./redux/actions/searchAction";
import { dashboardLogin } from './services/auth.service';

let currentIndex = 0;
const brands = [
	{
		name: 'etam',
	},
	{
		name: 'undiz',
	},
	{
		name: '123',
	},
	{
		name: 'livy',
	},
];

window.dashboardLogin = async (key) => {
	await dashboardLogin(key);
}
const App = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const usersList = useSelector(state => state.usersList);
	const [searchParams] = useSearchParams();

	useEffect(() => {
		let time = (searchParams.get("time") ? parseFloat(searchParams.get("time")) : 5) * 60000;
		const kioskingData = sessionStorage.getItem('kiosking');
		if (kioskingData) {
			time = JSON.parse(kioskingData).time;
		}
		if (window.location.href.indexOf("?kiosk") > -1 || kioskingData) {
			dispatch(isKiosking(true));
			setInterval(() => {
				currentIndex++;
				const brand = brands[currentIndex % brands.length];

				return navigate(`/dashboard/${brand.name}`);
			}, time);
			sessionStorage.setItem('kiosking', JSON.stringify({ time }));
		}
	}, []);
	if (usersList.isLogged) {
		return (
			<Fragment>
				<components.sidebar />
				<Routes>
					<Route path='/' element={<Navigate to="/dashboard/etam" replace />} />
					<Route path='/dashboard/:id' element={<pages.dashboard />} />
					<Route path="*" element={<pages.notfound />} />
				</Routes>
			</Fragment>
		)
			;
	} else {
		return (
			<Routes>
				<Route path='/' element={<pages.login />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		)
	}
};

export default App;
