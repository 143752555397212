
import { GET_USERS, USERS_ERROR, IS_LOGGED } from '../types';

export const setUserInfo = (data) => async dispatch => {
  if (data) {
    dispatch( {
      type: GET_USERS,
      payload: data
    })
    dispatch({
      type: IS_LOGGED,
      payload: true
    })
  } else {
    dispatch( {
      type: USERS_ERROR,
      payload: data,
    })
    dispatch({
      type: IS_LOGGED,
      payload: false
    })
  }
}
