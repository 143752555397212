import {useEffect,useState} from 'react';
import { components } from '../../utils/appRessource';

import { useDispatch } from 'react-redux';
import { setFilterSite } from '../../redux/actions/searchAction';

function Home() {
  const dispatch = useDispatch();
  const currentId = 'home';
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentId === 'home') {
      dispatch(setFilterSite(''));
      setIsLoading(false);
    }
  //eslint-disable-next-line
  }, [currentId]);

  return (
    <div className="home-page page">
      <components.header />
        {
          !isLoading &&
          <div className='page-content'>
            <components.pieChart isHome={true}/>
            <components.list isHome={true}/>
          </div>
        }
    </div>
  );
}

export default Home;