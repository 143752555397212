import { setUserInfo } from "../redux/actions/userAction";
import store from "../redux/store";

export async function login(email, password) {

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ username: email, password }),
		mode: "cors",
	};

	const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions);
	const data = await response.json();
	sessionStorage.setItem("loginInfo", JSON.stringify({ email, password }));
	if (data.token) {
		store.dispatch(setUserInfo(data));
		setTimeout(() => relogin(), 600000);
	}
	return data;
}

export async function dashboardLogin(key) {
	logout();
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ keyId: key }),
		mode: "cors",
	};

	const response = await fetch(`${process.env.REACT_APP_API_URL}/loginDashboard`, requestOptions);
	const data = await response.json();
	sessionStorage.setItem("dashboardInfo", JSON.stringify({ key }));
	if (data.token) {
		store.dispatch(setUserInfo(data));
		setTimeout(() => relogin(), 600000);
	}
	return data;
}

export function relogin() {
	try {
		const info = sessionStorage.getItem("loginInfo");
		if (info) {
			const { email, password } = JSON.parse(info);
			return login(email, password);
		}
		const dashboardInfo = sessionStorage.getItem("dashboardInfo");
		if (dashboardInfo) {
			const { key } = JSON.parse(dashboardInfo);
			return dashboardLogin(key);
		}
		throw "Missing relogin info"
	} catch (err) {
		logout();
		store.dispatch(setUserInfo(null));
	}
}

export async function getToken() {
	const user = store.getState().usersList.users;
	if (user) {
		const token = user.token;
		const values = token.split(".");
		const tokenValue = atob(values[1]);
		const data = JSON.parse(tokenValue);
		const now = new Date();
		if (now.getTime() < data.exp * 1000 - 1) {
			return token;
		}
	}
	await relogin();
	return store.getState().usersList.users.token;
}

export function logout() {
	sessionStorage.removeItem("user");
	sessionStorage.removeItem("loginInfo");
}
