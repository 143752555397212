import * as types from '../types';

const initialState = {
    search: [],
    dataBusiness: [],
    error: '',
    loading: true,
    dateFrom: '',
    dateTo: '',
    site: '',
    kiosking: false
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SEARCH:
            return {
                ...state,
                search: action.payload,
                loading: false
            }
        case types.SEARCH_ERROR:
            return {
                loading: false,
                error: action.payload
            }
        case types.FILTER_DATETO:
            return {
                ...state,
                loading: false,
                dateTo: action.payload
            }
        case types.FILTER_DATEFROM:
            return {
                ...state,
                loading: false,
                dateFrom: action.payload
            }
        case types.FILTER_SITE:
            return {
                ...state,
                loading: false,
                site: action.payload
            }
        case types.GET_DATABUSINESS:
            return {
                ...state,
                dataBusiness: action.payload,
                loading: false
            }
        case types.DATABUSINESS_ERROR:
            return {
                loading: false,
                error: action.payload
            }
        case types.IS_KIOSKING:
            return {
                kiosking: action.payload
            }
        default:
            return state
    }
};

export default searchReducer;