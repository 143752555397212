import React, {useState} from 'react';
import {Navigate, NavLink} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {setUserInfo} from '../../redux/actions/userAction';

import {components, icons, logos} from '../../utils/appRessource';
import './sidebar.scss';

function SideBar() {
  const dispatch = useDispatch();
  const usersList = useSelector(state => state.usersList);
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleLogOut = async () => {
    setLoading(true);
    dispatch(setUserInfo(null))
    setLoading(false);
    <Navigate to="/login" replace={true} />
  }

  return (
    <div className="sidebar__container pt-4">
      <ul className="sidebar__linkContainer">
        <li className="sidebar__link -home">
          <NavLink to="/">
            <icons.home />
          </NavLink>
        </li>
        <li className="sidebar__link -logo -etam">
          <NavLink to="/dashboard/etam">
            <logos.etam />
          </NavLink>
        </li>
        <li className="sidebar__link -logo -undiz">
          <NavLink to="/dashboard/undiz">
            <logos.undiz />
          </NavLink>
        </li>
        <li className="sidebar__link -logo -maison123">
          <NavLink to="/dashboard/123">
            <logos.maison123 />
          </NavLink>
        </li>
        <li className="sidebar__link -logo -livy">
          <NavLink to="/dashboard/livy">
            <logos.livy />
          </NavLink>
        </li>
      </ul>
      <button type="button" className ="sidebar__link -logout flex justify-center items-center" onClick={handleOpenDialog}>
        {
            isLoading && usersList
          ?
            <components.loader color={"white"}/>
          :
            <icons.logout />
        }
      </button>
      <components.dialogLogout open={openDialog} onClose={handleCloseDialog} onLogout={handleLogOut}/>
    </div>
  );
}

export default SideBar;
