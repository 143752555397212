import { GET_USERS, USERS_ERROR, IS_LOGGED} from '../types';

const initialState = {
    users: [],
    error: '',
    loading: true,
    isLogged: false
}

const userReducer = (state = initialState, action) => {
  switch(action.type){
      case GET_USERS:
        return {
          ...state,
          users:action.payload,
          loading:false
        }
      case USERS_ERROR:
        return{
          loading: false, 
          error: action.payload 
        }
      case IS_LOGGED:
        return{
          ...state,
          isLogged:action.payload,
          loading:false
        }
      default: return state
  }
};

export default userReducer;